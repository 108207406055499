<template>
    <div class="mt-6 lg:mt-10 lg:col-span-2 flex flex-col gap-6">
        <div class="flex flex-col gap-y-2">
            <label class="text-body-4 block text-label" for="photo-creator-name">
                Fotó(k) készítője*
            </label>
            <input class="input-with-border w-full" id="photo-creator-name" type="text" autocomplete="name" name="imageAuthor"
                   v-model="uploaderName" @input="uploaderNameChanged" :disabled="uploaderUnknown">
        </div>
        <div class="flex items-start gap-4">
            <label class="relative flex items-center cursor-pointer">
                <input type="checkbox" class="input-checkbox peer" id="photo-creator-unknown" v-model="uploaderUnknown" @change="uploaderUnknownChanged"/>
                <span class="input-checkbox-checkmark">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M22 2H2V22H22V2ZM6.414 10.172L10.414 14.172L17.586 7L19 8.414L10.414 17L5 11.586L6.414 10.172Z" fill="currentColor"/>
                        </svg>
                    </svg>
                </span>
            </label>
            <label for="photo-creator-unknown" class="text-body-3 text-primary">
                A fotó(k) készítője ismeretlen.
            </label>
        </div>
    </div>
    <div class="col-span-4 grid grid-cols-subgrid my-10 lg:my-20 gap-y-5 lg:gap-y-20">
        <div class="col-span-4 lg:col-span-2" v-for="(image, idx) of uploadedImages">
            <div class="aspect-[3/2] bg-gray-50 dark:bg-gray-950 relative flex items-center justify-center">
                <img class="w-full h-full object-center object-contain" :src="image.src" alt="" :srcset="image.srcset">
                <span class="absolute bottom-4 left-4 bg-body grid place-items-center w-6 h-6 text-xs">{{ idx + 1 }}</span>
                <button class="absolute top-4 right-4 p-1 bg-body" type="button" @click.prevent="deleteUploadedImage(idx)" title="Kép törlése">
                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M8.33333 8.62957L11.7037 11.9999L12.6667 11.037L9.29628 7.66662L12.6667 4.29621L11.7037 3.33325L8.33333 6.7036L4.96302 3.33325L4 4.29621L7.37045 7.66662L4.00014 11.037L4.96308 11.9999L8.33333 8.62957Z" fill="currentColor"/>
                    </svg>
                </button>
                <div class="absolute bottom-4 right-4 flex items-center gap-2">
                    <button class="p-1 bg-body" type="button"
                            :class="{ 'pointer-events-none text-gray-300 dark:text-gray-600': idx === 0 }"
                            @click.prevent="moveUploadedImageUp(idx)"
                            title="Mozgatás előrébb">
                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M5.73428 7.23077L13 7.23077L13 8.76923L5.73428 8.76923L8.65286 11.9123L7.64286 13L3 8L7.64286 3L8.65286 4.08769L5.73428 7.23077Z" fill="currentColor"/>
                        </svg>
                    </button>
                    <button class="p-1 bg-body" type="button"
                            :class="{ 'pointer-events-none text-gray-300 dark:text-gray-600': idx === uploadedImages.length - 1 }"
                            @click.prevent="moveUploadedImageDown(idx)"
                            title="Mozgatás hátrább">
                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M10.2657 8.76923H3V7.23077H10.2657L7.34714 4.08769L8.35714 3L13 8L8.35714 13L7.34714 11.9123L10.2657 8.76923Z" fill="currentColor"/>
                        </svg>
                    </button>
                </div>
            </div>
            <input type="hidden" name="assetId[]" :value="image.assetId">
        </div>
        <div class="col-span-4 lg:col-span-2" v-if="isUploading">
            <div class="aspect-[3/2] w-full border border-dashed border-gray-400 dark:border-gray-600 flex flex-col items-center gap-2 justify-center pointer-events-none">
                <div>
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M20.5 12C20.5 16.6944 16.6944 20.5 12 20.5C7.30558 20.5 3.5 16.6944 3.5 12C3.5 7.30558 7.30558 3.5 12 3.5C16.6944 3.5 20.5 7.30558 20.5 12ZM22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12ZM12.1299 12L16.7461 17.1114C15.5247 18.2151 13.9058 18.8873 12.1299 18.8873C8.3262 18.8873 5.24267 15.8037 5.24267 12C5.24267 8.19627 8.3262 5.11274 12.1299 5.11274V12Z" fill="currentColor"/>
                    </svg>
                </div>
                <div class="text-body-4 lg:text-body-3">Fotó(k) feltöltése folyamatban...</div>
            </div>
        </div>
        <div class="col-span-4 lg:col-span-2" v-if="uploadedImages.length + uploadingImages.length < 5 && ! isUploading">
            <div class="aspect-[3/2] w-full border border-dashed border-gray-400 dark:border-gray-600 flex flex-col items-center gap-2 justify-center relative">
                <div class="relative">
                    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M33 8H7V30H26V28H9V10H31V23H33V8ZM28 30H31V33H33V30H36V28H33V25H31V28H28V30ZM11 26L17.75 14L22.25 20.8571L24.5 19.1429L29 26H11Z" fill="currentColor"/>
                    </svg>
                </div>
                <div class="text-body-4 lg:text-body-3 relative">Húzd ide a feltölteni kívánt fotó(ka)t vagy</div>
                <button class="btn-link h-10 relative z-20" type="button" @click.prevent="uploadButtonClick">
                    <span>Fotó hozzáadása</span>
                </button>
                <input type="file" class="absolute z-10 opacity-0 inset-0"
                       accept="image/png,image/jpeg" multiple
                       ref="imageInput"
                       @change="startUpload">
            </div>
        </div>
    </div>
    <div class="col-span-4 lg:col-span-3 mb-10">
        <div class="flex items-start gap-4">
            <label class="relative flex items-center cursor-pointer">
                <input type="checkbox" class="input-checkbox peer" id="consent-given" v-model="consentGiven"/>
                <span class="input-checkbox-checkmark">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M22 2H2V22H22V2ZM6.414 10.172L10.414 14.172L17.586 7L19 8.414L10.414 17L5 11.586L6.414 10.172Z" fill="currentColor"/>
                        </svg>
                    </svg>
                </span>
            </label>
            <label for="consent-given" class="text-body-3 text-primary link-inside-underline" v-html="consentLabel"></label>
        </div>
    </div>
    <div class="col-span-4 flex flex-col gap-6 lg:flex-row">
        <button class="btn" :disabled="! formValid">
            Tovább a történethez
            <span class="icon">
                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M10.2657 8.76923H3V7.23077H10.2657L7.34714 4.08769L8.35714 3L13 8L8.35714 13L7.34714 11.9123L10.2657 8.76923Z" fill="currentColor"/>
                </svg>
            </span>
        </button>
        <button class="btn-link hidden" :disabled="! formValid" type="submit" name="saveAsDraft" value="1">
            <span>Mentés vázlatként</span>
        </button>
        <button class="btn-link save-as-draft-trigger" :disabled="! formValid" type="button">
            <span>Mentés vázlatként</span>
        </button>
        <a class="lg:hidden btn-link" href="#" @click.prevent="prevStep()" v-if="! isFirstStep">
            <span class="icon">
                <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M5.73428 7.73077L13 7.73077L13 9.26923L5.73428 9.26923L8.65286 12.4123L7.64286 13.5L3 8.5L7.64286 3.5L8.65286 4.58769L5.73428 7.73077Z" fill="currentColor"/>
                </svg>
            </span>
            <span>Vissza</span>
        </a>
    </div>
</template>

<script>
export default {
    name: "storyUploadStep2"
}
</script>

<script setup>
import {computed, ref} from "vue";

const props = defineProps(['appRoot']);
const { appRoot } = props;

const isFirstStep = appRoot.dataset.isFirstStep === '1';
const imageInput = ref(null);
const isUploading = ref(false);
const uploadedImages = ref([]);
const uploadingImages = ref([]);

const loadImages = JSON.parse(appRoot.dataset.loadImages || '[]');
if (loadImages.length) {
    loadSessionImages(loadImages);
}
const entryId = JSON.parse(appRoot.dataset.entryId || 'null');
const uploaderName = ref( appRoot.dataset.uploaderName || '' );
const uploaderUnknown = ref(appRoot.dataset.uploaderName === '');
const consentGiven = ref(appRoot.dataset.consentGiven === '1');
const consentLabel = decodeURIComponent(appRoot.dataset.consentLabel);

const formValid = computed(() => {
    return uploadedImages.value.length > 0 && (uploaderName.value.length > 0 || uploaderUnknown.value) && consentGiven.value;
});

function uploaderUnknownChanged() {
    if (uploaderUnknown.value) {
        uploaderName.value = '';
    }
}

function uploaderNameChanged() {
    if (uploaderName.value.length > 0) {
        uploaderUnknown.value = false;
    }
}

function uploadButtonClick() {
    imageInput.value.click();
}

async function loadSessionImages(assetIds) {
    isUploading.value = true;
    try {
        const params = new URLSearchParams({ ids: assetIds });
        const response = await fetch('/jelen/story-upload/get-session-images?' + params, {cache: 'no-cache'});
        const result = await response.json();
        if (result.success) {
            if (result.images && result.images.length) {
                uploadedImages.value.push(...result.images);
            }
        }
    } catch (e) {
        // TODO handle errors
    } finally {
        isUploading.value = false;
    }
}

async function startUpload() {
    const filesInput = imageInput.value;
    const csrfTokenName = window.csrfTokenName;
    const csrfTokenValue = document.getElementsByName(csrfTokenName)[0];
    if (! csrfTokenValue) {
        return;
    }
    const fd = new FormData();

    if (filesInput.files.length > 0) {
        for (let i = 0; i < Math.min(5, filesInput.files.length); i++) {
            fd.append('images[]', filesInput.files[i]);
        }
    } else {
        return; // nothing to upload
    }

    fd.set(csrfTokenName, csrfTokenValue.value);
    fd.set('action', 'jelen/story-upload/upload-images');

    try {
        isUploading.value = true;
        const response = await fetch('/', {method: 'post', cache: 'no-cache', body: fd});
        const result = await response.json();
        if (result.success) {
            filesInput.value = '';
            if (result.images && result.images.length) {
                uploadedImages.value.push(...result.images);
            }
        }
    } catch (e) {
        // TODO handle errors
    } finally {
        isUploading.value = false;
    }
}

function deleteUploadedImage(idx) {
    uploadedImages.value.splice(idx, 1);
}

function moveUploadedImageUp(idx) {
    [uploadedImages.value[idx - 1], uploadedImages.value[idx]] = [uploadedImages.value[idx], uploadedImages.value[idx - 1]];
}
function moveUploadedImageDown(idx) {
    [uploadedImages.value[idx + 1], uploadedImages.value[idx]] = [uploadedImages.value[idx], uploadedImages.value[idx + 1]];
}

function prevStep() {
    const formElem = document.querySelector('form.prev-step');
    if (formElem) {
        formElem.submit();
    }
}

</script>
