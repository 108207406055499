export function initMultipleCampaignsBlock() {
    const blocks = document.querySelectorAll('section.multiple-campaigns');
    if (blocks.length) {
        reCalcCampaignsBlocks(blocks);
        window.addEventListener('resize', () => {
            reCalcCampaignsBlocks(blocks);
        });
        for (const block of blocks) {
            initButtons(block);
        }
    }
}

function reCalcCampaignsBlocks(blocks) {
    for (const block of blocks) {
        reCalcCampaignsBlock(block);
    }
}

function reCalcCampaignsBlock(block) {
    const links = block.querySelectorAll('a');
    for (const link of links) {
        const textContainer = link.querySelector('.text-container');
        if (textContainer && link) {
            link.style.setProperty('--text-h', textContainer.offsetHeight + 'px');
        }
    }
}

function initButtons(block) {
    const carousel = block.querySelector('.overflow-x-auto');
    const prevButton = document.querySelector('.arrow.prev');
    const nextButton = document.querySelector('.arrow.next');

    if (!carousel || !prevButton || !nextButton) return;

    const items = [...carousel.querySelectorAll('.snap-start')];

    function scrollToItem(direction) {
        const currentScroll = carousel.scrollLeft;
        const carouselWidth = carousel.clientWidth;
        const computedStyle = window.getComputedStyle(carousel.children[0]);
        const paddingLeft = parseInt(computedStyle.paddingLeft, 10) || 0;

        if (direction === 'next') {
            const targetItem = items.find(item => item.offsetLeft - paddingLeft > currentScroll + 1);
            if (targetItem) {
                carousel.scrollTo({ left: targetItem.offsetLeft - paddingLeft, behavior: 'smooth' });
            }
        } else {
            const targetItem = [...items].reverse().find(item => item.offsetLeft + item.clientWidth - paddingLeft < currentScroll - 1);
            if (targetItem) {
                carousel.scrollTo({ left: targetItem.offsetLeft - paddingLeft, behavior: 'smooth' });
            } else {
                carousel.scrollTo({ left: 0, behavior: 'smooth' });
            }
        }
    }

    function updateButtons() {
        const scrollLeft = carousel.scrollLeft;
        const maxScrollLeft = carousel.scrollWidth - carousel.clientWidth;

        prevButton.disabled = scrollLeft <= 0;
        nextButton.disabled = scrollLeft >= maxScrollLeft;
    }

    prevButton.addEventListener('click', () => scrollToItem('prev'));
    nextButton.addEventListener('click', () => scrollToItem('next'));

    carousel.addEventListener('scroll', updateButtons);

    updateButtons();
}
