const STORAGE_KEY_CAMPAIGN_DISMISSED = "jelenarchivumFeaturedCampaignDismissed";
const STORAGE_KEY_CAMPAIGN_DATE = "jelenarchivumFeaturedCampaignLastSeen";


export function initFeaturedCampaign() {
    const featuredCampaign = document.querySelector(".featured-campaign");
    if (!featuredCampaign) return;

    const today = new Date().toISOString().split("T")[0];

    const lastSeen = localStorage.getItem(STORAGE_KEY_CAMPAIGN_DATE);
    const dismissed = localStorage.getItem(STORAGE_KEY_CAMPAIGN_DISMISSED);

    if (lastSeen !== today || !dismissed) {
        featuredCampaign.classList.add("show");
    }

    const closeButton = featuredCampaign.querySelector(".featured-campaign-close");
    if (closeButton) {
        closeButton.addEventListener("click", (e) => {
            e.preventDefault();
            featuredCampaign.classList.remove("show");
            localStorage.setItem(STORAGE_KEY_CAMPAIGN_DISMISSED, "1");
            localStorage.setItem(STORAGE_KEY_CAMPAIGN_DATE, today);
        });
    }
}
