export function initCopyLinks() {
    const links = document.querySelectorAll('.copy-link');
    for (const link of links) {
        initCopyLink(link);
    }
}

function initCopyLink(link) {
    link.addEventListener('click', (e) => {
        e.preventDefault();
        copyTextToClipboard(e.target.closest('a').dataset.url).then(() => {
            if (e.target.closest('a').dataset.closeTooltip) {
                const button = document.querySelector('[data-target="' + e.target.closest('a').dataset.closeTooltip + '"]');
                if (button) {
                    button.click();
                }
            }
        });
    });
}

function fallbackCopyTextToClipboard(text) {
    const textArea = document.createElement("textarea");
    textArea.value = text;

    textArea.style.top = "0";
    textArea.style.left = "0";
    textArea.style.position = "fixed";

    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();

    try {
        return document.execCommand('copy');
    } catch (err) {
        return false;
    } finally {
        document.body.removeChild(textArea);
    }
}

function copyTextToClipboard(text) {
    if (!navigator.clipboard) {
        return Promise.resolve(fallbackCopyTextToClipboard(text));
    }
    return navigator.clipboard.writeText(text);
}
