<template>
    <a :href="result.url" class="masonry-item"
       :class="{ 'pointer-events-none': result.adultContent && ! canViewAdultContent }"
       :tabindex="result.adultContent && ! canViewAdultContent ? -1 : 0"
       :data-masonry-id="result.masonryId ? result.masonryId : 'x'"
       :id="'masonry-item-' + (result.masonryId ? result.masonryId : 'x')"
       :data-story-id="result.id">
        <div class="relative mb-4 lg:mb-6">
            <img v-if="! result.adultContent || canViewAdultContent" class="w-full"
                 :src="props.mode === 'small' ? result.images.small : result.images.large"
                 :alt="result.title"
                 @load="emit('imageLoaded')">
            <adult-content-placeholder v-else @showPopup="showAdultContentPopup"></adult-content-placeholder>
            <span class="bg-black/60 backdrop-blur-lg block absolute bottom-2 right-2 p-1.5 text-body-4 leading-none flex items-center gap-1 text-white">
                {{ result.imagesCount }}
                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M14.5 1.5H1.5V14.5H14.5V1.5ZM3 13V3H13V13H3ZM4 12L7 5L9 9L10 8L12 12H4Z" fill="white"/>
                </svg>
            </span>
        </div>
        <div class="text-body-4 font-medium mb-1 lg:text-body-3 lg:mb-0">{{ result.year }}</div>
        <div class="text-body-2 font-medium lg:text-body-1">{{ result.title }}</div>
        <div class="text-body-4 font-medium lg:text-body-3 text-secondary mb-2">{{ result.location }}</div>
        <p class="text-body-3 line-clamp-4 lg:text-body-2 lg:line-clamp-2">{{ result.description }}</p>
    </a>
</template>

<script>
export default {
    name: "item"
}
</script>

<script setup>

import AdultContentPlaceholder from "./adult-content-placeholder.vue";

const props = defineProps(['result', 'canViewAdultContent', 'mode']);
const emit = defineEmits(['imageLoaded']);

function showAdultContentPopup() {
    // TODO if we need this at all
}

</script>
