import { openLoginPopup } from "./header-share-login-popup";
export function initShareStory() {
    const shareButtons = document.querySelectorAll('.share-my-story');
    for (const shareButton of shareButtons) {
        shareButton.addEventListener('click', e => {
            e.preventDefault();
            if(window.innerWidth < 1024){
                scrollToTop(() => {
                    document.getElementById('mobile-menu-button').click();
                    waitForElementAndClick('.show-share-login-popup.mobile')
                });
            }else{
                scrollToTop(() => {
                    openLoginPopup(e);
                });
            }
        });
    }
}

function waitForElementAndClick(selector, delay = 250) {
    const interval = setInterval(() => {
        const element = document.querySelector(selector);

        const rect = element.getBoundingClientRect();
        const style = window.getComputedStyle(element);

        if (rect.width > 0 && rect.height > 0 && style.display !== 'none' && style.visibility !== 'hidden' && style.opacity !== '0') {
            clearInterval(interval);
            element.click();
        }
    }, delay);
}

function scrollToTop(callback) {
    const start = window.scrollY;

    window.scrollTo({ top: 0, behavior: 'smooth' });

    function checkScroll() {
        if (window.scrollY === 0) {
            callback();
        } else {
            requestAnimationFrame(checkScroll);
        }
    }

    if (start !== 0) {
        requestAnimationFrame(checkScroll);
    } else {
        callback();
    }
}
