export function initHeaderSharePopup() {
    const openButtons = document.querySelectorAll('.show-share-login-popup');
    for (const openButton of openButtons) {
        openButton.addEventListener('click', openLoginPopup);
    }
    const popupCloseButtons = document.querySelectorAll('.share-login-popup .close-share-login-popup');
    for (const popupCloseButton of popupCloseButtons) {
        popupCloseButton.addEventListener('click', closeLoginPopup);
    }
}

export function openLoginPopup(e) {
    e.preventDefault();
    const extraClass = e.target.classList.contains('mobile') ? 'mobile' : 'desktop';
    const popup = document.querySelector(`.share-login-popup.${extraClass}`);
    popup.classList.add('show');
    if (extraClass === 'mobile') {
        document.body.classList.add('scrim-over-header-visible');
    } else {
        setTimeout(() => {
            document.body.addEventListener('click', maybeCloseDesktopLoginPopup);
        }, 50);
    }
}

function closeLoginPopup(e) {
    const popup = e.target.closest('.share-login-popup');
    const extraClass = popup.classList.contains('mobile') ? 'mobile' : 'desktop';
    popup.classList.remove('show');
    if (extraClass === 'mobile') {
        document.body.classList.remove('scrim-over-header-visible');
    }
}

function maybeCloseDesktopLoginPopup(e) {
    if (e.target.closest('.share-login-popup')) {
        return;
    }
    closeLoginPopup({ target: document.querySelector('.share-login-popup.desktop .close-share-login-popup') });
    document.body.removeEventListener('click', maybeCloseDesktopLoginPopup);
}
